import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';
import { DropdownListItem, DropdownListOptions } from './types';


@Component({
    name: 'DropdownList',
    filters: {}
})
export default class DropdownList extends Vue {
    search: string = '';
    openDropdown: boolean = false;
    hovering: boolean = false;
    hoverText: string = '';
    styleObject: any = {};
    dropdownElement: any = {}
    active: number = -1
    searchElementId: number = -1
    public activeLetter = '';
    public selectedIndex: number = -1;
    selectableLableHover: boolean = false
    public publicUniqueId: number = Math.floor(Math.random() * 100000000000);

    @Prop()
    classes!: string[]

    @Prop({ default: true })
    autoSelectionAllowed!: boolean

    @Prop({
        default: {
            singleSelect: false,
            showSelectLabel: true,
            firstSelectLabel: 'Select',
            id: 0,
            value: 'Select',
            label: '',
            error: false,
            errorText: '',
            search: false,
            disable: false,
            dropdownList: [{ id: 0, value: 'Select' }] as DropdownListItem[]
        } as DropdownListOptions
    })
    dropdown!: DropdownListOptions;

    @Prop()
    slotObj!: { slotLabel: string, slotType: string };

    toggleDropdown(e?: any, close?: boolean) {
        if (this.dropdown.disable) {
            return;
        }
        this.openDropdown = this.openDropdown ? APP_CONST.FALSE : (close ? APP_CONST.FALSE : APP_CONST.TRUE);
        this.clearSearch();
        this.hovering = !this.hovering;
        if (this.openDropdown) {
            this.active = APP_CONST.MINUS_ONE;
            setTimeout(() => {
                this.dropdownElement.children = [...document.getElementsByClassName("dropdown-menu")];
                if (this.dropdown.search) {
                    let inputElement = document.getElementById('dropdownSearchInput');
                    inputElement && inputElement.focus()
                    if (this.dropdown.id) {
                        let activeEle = document.getElementsByName(`drop-a-${this.dropdown.id}`)[0];
                        if (activeEle) {
                            activeEle.scrollIntoView({ block: "center", behavior: "smooth" });
                        }
                    }
                }
                else {
                    let element = document.getElementById("drop-a-0");
                    if (element) {
                        element.focus();
                        this.active = 0;
                        this.dropdownElement.children[this.active].classList.add("dropbtn-selected");
                    }
                    if (this.dropdown.id) {
                        let activeEle = document.getElementsByName(`drop-a-${this.dropdown.id}`)[0];
                        if (activeEle) {
                            activeEle.focus();
                            const index = this.dropdownElement.children.findIndex((el: any, index: number) => {
                                const toFind = `drop-a-${this.dropdown.id}`;
                                if (el.children[0].name == toFind) {
                                    return index
                                }
                            });
                            if (index >= APP_CONST.ZERO) {
                                this.dropdownElement.children[this.active].classList.remove("dropbtn-selected");
                                this.active = index;
                                this.dropdownElement.children[this.active].classList.add("dropbtn-selected");
                                this.dropdownElement.children[this.active] && this.dropdownElement.children[this.active].scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
                            }
                        }
                    }
                }
            }, 10)
        }
        if (this.openDropdown && this.selectedIndex > -1) {
            let dropdownMenuItems = this.dropdownElement && this.dropdownElement.children
            dropdownMenuItems[this.active] && dropdownMenuItems[this.active].scrollIntoView({ block: "center", inline: "center", behavior: "smooth" })
        }
        if (this.active > -1 && e && e.keyCode == 13 && !this.openDropdown) {
            this.selectedIndex = this.active;
            let selected = this.dropdown.dropdownList[this.active]
            this.select(selected)
        }
    }

    /* istanbul ignore next */
    mounted() {
        document.addEventListener("keydown", (e) => {
            switch (e.key) {
                case "ArrowUp":
                case "ArrowDown":
                    if (this.openDropdown)
                        e.preventDefault();
                    break;
            }
        });
        this.selectFirstElement();
    }

    @Watch('dropdown.dropdownList', { deep: true })
    onDropdownListChange(old: any, newVal: any) {
        if (old !== newVal)
            this.selectFirstElement();
    }

    selectFirstElement() {
        if (this.dropdown.dropdownList && this.dropdown.dropdownList.length <= APP_CONST.ONE && this.autoSelectionAllowed) {
            let dropDownValues: any = this.dropdown.dropdownList.length == APP_CONST.ZERO ? { id: this.dropdown.id ? this.dropdown.id : 0, value: this.dropdown.value } : this.dropdown.dropdownList[APP_CONST.ZERO]
            this.select(dropDownValues)
            let element: any = document.getElementById(`dropdown-label-${this.publicUniqueId}`)
            element && element.classList && element.classList.add("dropdown-active");
        }
        else {
            let element: any = document.getElementById(`dropdown-label-${this.publicUniqueId}`)
            element && element.classList && element.classList.remove("dropdown-active");
        }
    }

    onDropdownOpen(e: any, attribute?: string) {
        let searchElement = document.getElementById('dropdownSearchInput');
        if (document.activeElement !== searchElement) {
            if (e.code == "Space") {
                e.preventDefault();
                return false;
            }
        }
        if (e.keyCode == 27) {
            this.toggleDropdown({}, true);
            this.refocusDD()
        }
        if (attribute == "fromLi" && e.keyCode == 9 && !e.shiftKey) {
            this.toggleDropdown({}, false)
            this.active = -1;
            return;
        } else if (attribute == "fromLi" && e.keyCode == 9 && e.shiftKey) {
            this.active = -1;
            setTimeout(() => {
                if (this.dropdown.search) {
                    let inputElement = document.getElementById('dropdownSearchInput');
                    inputElement && inputElement.scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
                    this.goToSearch();
                }
            }, 10);
            return;
        }
        else if (attribute == 'fromSearchInput' && (e.keyCode == 38 || e.keyCode == 40)) {
            e.preventDefault();
            return;
        }
        else if (attribute == 'fromSearchInput' && e.keyCode == 9 && !e.shiftKey) {
            this.active = 0;
        }
        let dropdownMenuItems = this.dropdownElement && this.dropdownElement.children
        let inputElement = document.getElementById('dropdownSearchInput');

        if (this.openDropdown) {
            const input = String.fromCharCode(e.keyCode)
            if (e.keyCode == 40) {
                if (this.active < this.dropdownElement.children.length - 1)
                    this.active++;
            }
            else if (e.keyCode == 38) {
                if (this.active == APP_CONST.ZERO) {
                    this.active;
                }
                else
                    this.active--;
            }
            else if (/[a-zA-Z0-9]/.test(input) && (document.activeElement !== inputElement)) {
                let loopStart = this.activeLetter == input.toLowerCase() ? this.active + 1 : 0;
                for (let i = loopStart; i < this.dropdownElement.children.length; i++) {
                    let val = dropdownMenuItems[i].innerText.charAt(0).toLowerCase()
                    let inp = input.toLowerCase()
                    if (val == inp) {
                        this.active = i;
                        this.activeLetter = inp;
                        break
                    }
                }
            }
            setTimeout(() => {
                let currentElm: any = document.querySelector('.dropbtn-selected a') as HTMLElement;
                currentElm && currentElm.focus()
            })
        }
        this.dropdownElement.children.forEach((drop: any) => {
            drop && drop.classList && drop.classList.remove('dropbtn-selected');
        });
        this.dropdownElement && this.dropdownElement.children[this.active] && this.dropdownElement.children[this.active].classList.add('dropbtn-selected');
        if (this.active > -1) {
            this.dropdownElement.children[this.active].focus();
            this.dropdownElement.children[this.active].classlist && this.dropdownElement.children[this.active].classlist.add('dropbtn-selected');
            dropdownMenuItems[this.active] && dropdownMenuItems[this.active].scrollIntoView({ block: "center", inline: "center", behavior: "smooth" })
        }
    }

    goToSearch() {
        let inputElement = document.getElementById('dropdownSearchInput');
        inputElement && inputElement.focus()
    }

    checkKey(e: any) {
        if (e.keyCode == 38 || e.keyCode == 40) {
            if (this.openDropdown) {
                this.onDropdownOpen(e, 'fromLi')
            } else {
                this.toggleDropdown(e)
            }
        }
    }

    select(selection: { id: number, value: string }) {
        if (selection && this.dropdown.id == selection.id) {
            this.openDropdown = false;
            this.refocusDD();
            this.hovering = false;
            return;
        }
        this.dropdown.id = selection && selection.id;
        this.dropdown.value = selection && selection.value;
        if (this.dropdown.id >= APP_CONST.ZERO || typeof (this.dropdown.id) == 'string') {
            this.$emit('onSelectionChange', { id: this.dropdown.id, value: this.dropdown.value } as DropdownListItem);
        }
        this.openDropdown = false;
        this.refocusDD()
        this.hovering = false;
    }

    filterList(list: [{ id: number, value: string }], search: string): any {
        search = search.trim();
        if (!search) {
            return list;
        }
        const dropList = list.filter((el) => el.value.toLowerCase().includes(search.toLowerCase()));
        return dropList;
    }

    clearSearch() {
        this.search = '';
    }

    refocusDD() {
        let btnElment = document.getElementById(`dropdown-label-${this.publicUniqueId}`)
        btnElment && btnElment.focus();
    }

    // Just to improve user Experience
    changeFocusOnHover(index: number) {
        this.dropdownElement && this.dropdownElement.children && this.dropdownElement.children.forEach((drop: any) => {
            drop && drop.classList && drop.classList.remove('dropbtn-selected');
        });
        if (index > -1) {
            this.active = index;
            // this check is needed to ensure the dropdown and elements inside the dropdown exist
            // due to the onDropdownOpen function having a 10ms timeout
            if (this.dropdownElement.children && this.dropdownElement.children.length && this.dropdownElement.children[this.active]) {
                this.dropdownElement.children[this.active].classList.add("dropbtn-selected");
            }
        }
    }

    /**
     * Checks if the provided dropdown element ID is valid.
     *
     * @param id - The dropdown element ID to validate.
     * @returns A boolean indicating whether the ID is valid or not.
     */
    isDropdownElementIdValid(id: number | string): boolean {
      if (typeof id === 'number') {
        return id > -1;
      } else if (typeof id === 'string') {
        return !!id;
      }
      return false;
    }
}