




































import DropdownList from '@/ui-components/dropdownListBx/DropdownList';
export default DropdownList;
